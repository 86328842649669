import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Step4(props: any) {
  const name = props.petName;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="80vh"
    >
      <Grid item width="80%">
        <img
          src={process.env.PUBLIC_URL + "/images/perro-gato-2.svg"}
          alt="tag"
          style={{ maxWidth: 200 }}
        />
      </Grid>
      <Grid item width="80%" mt={2.5}>
        <Typography variant="h6">
          Hola {name}
        </Typography>
      </Grid>
      <Grid item width="80%" m={3}>
        <Typography
          align="justify"
          fontSize="0.85rem"
          color="gray"
          textAlign="justify"
        >
          La placa de tu mascota fue activada con éxito. Por favor, no olvides
          mantener tu información de contacto siempre actualizada (Menú &gt;
          Información de contacto).
        </Typography>
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          variant="contained"
          fullWidth
          component={Link} to="/contacto"
          onClick={props.addPet}
          sx={{ textTransform: "none" }}
        >
          Agregar contactos
        </Button>
      </Grid>
    </Grid>
  );
}

export default Step4;
