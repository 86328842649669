import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  Outlet
} from "react-router-dom";
import "./App.css";
import Register from "./components/account/register";
import Reset from "./components/account/reset";
import AccountSettings from "./components/account/settings";
import ContactInfo from "./components/contact/contacts";
import FooterAppBar from "./components/includes/footer/footer";
import HeaderAppBar from "./components/includes/header/header";
import Login from "./components/login/login";
import NoMatch from "./components/nomatch";
import AddPet from "./components/pets/add";
import PetFile from "./components/pets/file";
import PetInfo from "./components/pets/info";
import Pets from "./components/pets/pets";
import theme from "./theme/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Container disableGutters maxWidth="sm">
          <BrowserRouter>
            <HeaderAppBar />
            <Routes>
              <Route path="*" element={<NoMatch />} />
              <Route path="/" element={<Login />} />
              <Route path="/registrar" element={<Register />} />
              <Route path="/restablecer" element={<Reset />} />
              <Route path="/mascotas" element={<PrivateOutlet />}>
                <Route path="" element={<Pets />} />
              </Route>
              <Route path="/mascotas/agregar" element={<PrivateOutlet />}>
                <Route path="" element={<AddPet />} />
              </Route>
              <Route path="/mascotas/:id" element={<PetFile />} />
              <Route path="/mascotas/:tag/editar" element={<PrivateOutlet />}>
                <Route path="" element={<PetInfo />} />
              </Route>
              <Route path="/contacto" element={<PrivateOutlet />}>
                <Route path="" element={<ContactInfo />} />
              </Route>
              <Route path="/ajustes" element={<PrivateOutlet />}>
                <Route path="" element={<AccountSettings />} />
              </Route>
            </Routes>
            <FooterAppBar />
          </BrowserRouter>
        </Container>
      </div>
    </ThemeProvider>
  );
}

function PrivateOutlet() {
  const auth = localStorage.getItem("isUserLoggedIn");
  return auth ? <Outlet /> : <Navigate to="/" />;
}

export default App;
