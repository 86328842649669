import { Alert, Collapse, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

function Step1(props: any) {
  const [isInputCorrect, setIsInputCorrect] = useState(false);

  const isTagInvalid = props.isTagInvalid;
  const setIsTagInvalid = props.setIsTagInvalid;

  const setTag = props.setTag;
  const tag = props.tag;
  const control = new RegExp("^[a-zA-Z]{3}\\d{3}$");

  useEffect(() => {
    if (control.test(tag)) {
      setIsInputCorrect(true);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTag(event.target.value);
    if (control.test(event.target.value)) {
      setIsInputCorrect(true);
      return;
    }
    setIsInputCorrect(false);
    return;
  };
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      minHeight="56vh"
      mt={4}
    >
      <Grid item>
        <img
          src={process.env.PUBLIC_URL + "/images/perro-gato-1.svg"}
          alt="add pet"
          style={{ maxWidth: 150 }}
        />
      </Grid>
      <Grid item mt={2.5}>
        <Typography variant="h6">
          Agrega una mascota
        </Typography>
      </Grid>
      <Grid item margin={3} width="80%">
        <Typography align="justify" fontSize="0.85rem" color="gray">
          Ingresa el código colocado en la parte trasera de tu placa
          inteligente. Por ejemplo, si dice ID: ABC123, tu código es ABC123
        </Typography>
      </Grid>
      <Grid item width="80%">
        <TextField
          id="add-pet-code"
          label="Código de la placa"
          helperText={!isInputCorrect && "El código de la placa debe respetar el formato: ABC123"}
          variant="outlined"
          size="small"
          fullWidth
          error={!isInputCorrect}
          placeholder={"ABC123"}
          onChange={handleChange}
          value={tag}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
      </Grid>
      <Grid item mt={4}>
        <Typography variant="subtitle2">
          ¿Tienes inconvenientes?{" "}
          <Link to="https://wa.me/593997265507" target="_blank" style={{ textDecoration: "none" }}>
            Contáctanos aquí.
          </Link>
        </Typography>
      </Grid>
      <Grid item mt={4}>
        <Collapse in={isTagInvalid}>
          <Alert
            color={"error"}
            severity={"error"}
            action={
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setIsTagInvalid(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            El código de placa ingresado ya ha sido utilizado.
          </Alert>
        </Collapse>
      </Grid>
    </Grid>
  );
}

export default Step1;
