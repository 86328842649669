import { createTheme } from "@mui/material/styles";

let theme = createTheme({
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 15
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: 15
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: 100
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 200,
            fontSize: 15,
            border: "1px solid #1d1d1f",
            ":hover": {
              color: "#000",
              backgroundColor: "#FFF"
            }
          }
        }
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: "black"
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: "black"
          }
        }
      }
    },
    palette: {
      primary: {
        light: "#F4F5F6",
        main: "#1d1d1f",
        contrastText: "#FFF"
      },
      secondary: {
        light: "#a3b7ed",
        main: "#8ca6e9",
        dark: "#6274a3",
        contrastText: "#000"
      }
    },
    typography: {
      fontSize: 16,
      fontFamily: [
        "CircularStd-Light",
        "-apple-system",
        "BlinkMacSystemFont",
        "\"Segoe UI\"",
        "Roboto",
        "\"Helvetica Neue\"",
        "Arial",
        "sans-serif",
        "\"Apple Color Emoji\"",
        "\"Segoe UI Emoji\"",
        "\"Segoe UI Symbol\""
      ].join(","),
      h4: {
        fontSize: 30,
        fontFamily: "Moranga"
      },
      h6: {
        fontSize: 25,
        fontFamily: "CircularStd-Book",
        letterSpacing: -0.3,
        fontWeight: "normal"
      }
    }

  })
;

theme.typography.subtitle2 = {
  fontSize: "0.875rem"
};

theme = createTheme(theme, {
  palette: {
    menu: theme.palette.augmentColor({
      color: {
        main: "#F4F5F6",
        contrastText: "#000"
      },
      name: "menu"
    })
  }
});

theme = createTheme(theme, {
  palette: {
    cards: theme.palette.augmentColor({
      color: {
        main: "#F4F5F6"
      },
      name: "cards"
    })
  }
});

export default theme;
