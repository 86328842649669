import * as React from "react";
import { Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";

function AppMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleLogout = async () => {
    return fetch(process.env.REACT_APP_RINGO_API_HOST + "/logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
      .then(() => {
        setAnchorEl(null);
        localStorage.clear();
        navigate("/");
      })
      .catch((err) => console.log("err >> ", err));
  };

  return (
    <div>
      <IconButton
        data-testid="menuIconButton"
        size="large"
        edge="end"
        color="primary"
        aria-label="menu"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon
          sx={{
            boxSizing: "content-box",
            color: "black",
            backgroundColor: "menu.main",
            borderRadius: 1
          }}
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}
        elevation={0}
      >
        <MenuItem component={Link} to="/mascotas" onClick={handleClose}>
          Inicio
        </MenuItem>
        <MenuItem component={Link} to="/contacto" onClick={handleClose}>
          Información de contacto
        </MenuItem>
        <MenuItem component={Link} to="/ajustes" onClick={handleClose}>
          Ajustes
        </MenuItem>
        <MenuItem
          href="https://ringopets.com"
          target="_blank"
          component="a"
          onClick={handleClose}
        >
          Tienda
        </MenuItem>
        <MenuItem component={Link} to="/" onClick={handleLogout}>
          Log out
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AppMenu;
