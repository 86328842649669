import * as React from "react";
import {
  Autocomplete,
  Button, Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { get } from "../../../services/fetchers";
import { BreedModel } from "../../../models/breed.model";
import { UploadOptions } from "imagekit-javascript/dist/src/interfaces";
import { WarningAmber } from "@mui/icons-material";

function Step2(props: any) {
  const name = props.name;
  const setName = props.setName;
  const birthdate = props.birthdate;
  const setBirthdate = props.setBirthdate;
  const sex = props.sex;
  const setSex = props.setSex;
  const species = props.species;
  const setSpecies = props.setSpecies;
  const breed = props.breed;
  const setBreed = props.setBreed;
  const sterilized = props.sterilized;
  const setSterilized = props.setSterilized;
  const setUploadOptions = props.setUploadOptions;

  const [breeds, setBreeds] = useState([]);

  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageSelectedName, setImageSelectedName] = useState("");

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageSelectedName(e.target.files![0]?.name);
    setIsImageSelected(e.target.files![0] !== undefined);

    const imageKitAuth = await get("/imagekit/auth");
    const { signature, expire, token } = imageKitAuth;

    setUploadOptions({
      file: e.target.files![0],
      fileName: e.target.files![0]?.name,
      token,
      signature,
      expire
    } as UploadOptions);
  };

  const handleSexChange = (event: SelectChangeEvent) => {
    setSex(event.target.value as string);
  };

  const handleSpeciesChange = (event: SelectChangeEvent) => {
    setSpecies(event.target.value as string);

    if (event.target.value === "Gato") {
      get("/breeds/cat")
        .then((breedsData) => {
          const r = breedsData.map((b: BreedModel) => ({
            label: `${b.breed}`
          }));
          r.unshift({
            label: "Mestizo"
          });
          setBreeds(r);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }

    if (event.target.value === "Perro") {
      get("/breeds/dog")
        .then((breedsData) => {
          const r = breedsData.map((b: BreedModel) => ({
            label: `${b.breed}`
          }));
          r.unshift({
            label: "Mestizo"
          });
          setBreeds(r);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  };

  const handleSterilizationChange = (event: SelectChangeEvent) => {
    setSterilized(event.target.value === "Sí");
  };

  const handleBreedChange = (event: any, selectedBreed: string | null) => {
    const breed = selectedBreed as unknown as { label: string };
    setBreed(breed?.label);
  };

  return (
    <Grid
      container
      spacing={"10px"}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="60vh"
    >
      <Grid item width="80%" mb={2}>
        <Typography variant="h6">
          Agrega una mascota
        </Typography>
      </Grid>
      <Grid item width="80%">
        <TextField
          id="add-pet-name "
          label="Nombre o apodo"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
      </Grid>
      <Grid item width="80%">
        <DatePicker
          format={"DD/MM/YYYY"}
          label="Fecha de nacimiento / adopción"
          sx={{ width: "100%" }}
          onChange={(e: Dayjs | null) => setBirthdate(e?.format("DD/MM/YYYY"))}
          value={dayjs(birthdate, "DD/MM/YYYY")}
        />
      </Grid>
      <Grid item width="80%">
        <FormControl size="small" fullWidth>
          <InputLabel id="sex-select-label">Sexo</InputLabel>
          <Select
            labelId="sex-select-label"
            id="sex-select"
            label="Sexo"
            value={sex}
            onChange={handleSexChange}
          >
            <MenuItem value={"M"}>Macho</MenuItem>
            <MenuItem value={"H"}>Hembra</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item width="80%">
        <FormControl size="small" fullWidth>
          <InputLabel id="species-select-label">Especie</InputLabel>
          <Select
            labelId="species-select-label"
            id="species-select"
            label="Especie"
            value={species}
            onChange={handleSpeciesChange}
            fullWidth
          >
            <MenuItem value=""> <em>Elegir</em> </MenuItem>
            <MenuItem value={"Perro"}>Perro</MenuItem>
            <MenuItem value={"Gato"}>Gato</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item width="80%">
        <FormControl size="small" fullWidth>
          <Autocomplete
            id="register-breed"
            disablePortal
            options={breeds}
            renderInput={(params) => <TextField {...params} label="Raza" />}
            size="small"
            fullWidth
            componentsProps={{ popper: { style: { width: "fit-content" } } }}
            onChange={handleBreedChange}
            value={breed}
          />
        </FormControl>
      </Grid>
      <Grid item width="80%">
        <FormControl size="small" fullWidth>
          <InputLabel id="sterilization-select-label">
            ¿Se encuentra esterilizado?
          </InputLabel>
          <Select
            labelId="sterilization-select-label"
            id="sterilization-select"
            label="¿Se encuentra esterilizado?"
            value={sterilized}
            onChange={handleSterilizationChange}
            fullWidth
          >
            <MenuItem value={"Sí"}>Sí</MenuItem>
            <MenuItem value={"No"}>No</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item width="80%">
        {!isImageSelected &&
          <Chip icon={<WarningAmber />} label="Debes escoger una foto para tu mascota"></Chip>}
        {isImageSelected && <Chip color="success" label={imageSelectedName}></Chip>}
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          component="label"
          variant="contained"
          style={{ textTransform: "none" }}
          startIcon={<CloudUploadIcon style={{ color: "white" }} />}
          fullWidth
        >
          Fotografía de tu mascota
          <input type="file" onChange={handleImageUpload} accept={".jpeg, .jpg, .png"} hidden />
        </Button>
      </Grid>
    </Grid>
  );
}

export default Step2;
