import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import * as React from "react";

function NoMatch() {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
    >
      <Grid item width="80%">
        <img
          src={process.env.PUBLIC_URL + "/images/404-dog.svg"}
          alt="ringo logo"
          style={{ maxWidth: 120 }}
        />
      </Grid>
      <Grid item width="80%" mt={2.5} mb={2}>
        <Typography variant="h6">
          Error 404
        </Typography>
      </Grid>
      <Grid item width="80%" mt={2.5}>
        <Typography align="center" variant="body2">
          Oups! Parece que la página que buscas no existe.
        </Typography>
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          variant="contained"
          style={{ textTransform: "none" }}
          component={Link}
          to="/"
          fullWidth
        >
          Regresar al inicio.
        </Button>
      </Grid>
    </Grid>
  );
}

export default NoMatch;
