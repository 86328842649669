import {
  Alert, Autocomplete,
  Box,
  Button, Chip,
  Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  Drawer, FormControl,
  Grid,
  IconButton, InputLabel, MenuItem, Select, SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { del, get, put } from "../../services/fetchers";
import { PetModel } from "../../models/pet.model";
import { DrawerModel } from "../../models/drawer.model";
import CloseIcon from "@mui/icons-material/Close";
import { BreedModel } from "../../models/breed.model";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { UploadOptions } from "imagekit-javascript/dist/src/interfaces";
import ImageKit from "imagekit-javascript";

function PetInfo() {
  const { tag } = useParams();

  const [show_contact, setShow_contact] = useState(false);
  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [sex, setSex] = useState("");
  const [species, setSpecies] = useState("");
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({} as DrawerModel);
  const [editField, setEditField] = useState("");
  const [breeds, setBreeds] = useState([]);
  const [breed, setBreed] = useState("");
  const [sterilized, setSterilized] = React.useState(false);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageSelectedName, setImageSelectedName] = useState("");
  const [uploadOptions, setUploadOptions] = useState({} as UploadOptions);
  const [description, setDescription] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  useEffect(() => {
    get(`/pets/${tag}`).then((data: PetModel) => {
      console.log(data);
      setShow_contact(data.show_contact ?? false);
      setName(data.name);
      setBirthdate(data.birthdate);
      setSex(data.sex);
      setSpecies(data.species);
      handleSpeciesChange({ target: { value: data.species } } as SelectChangeEvent);
      setBreed(data.breed);
      setSterilized(data.sterilized);
      setDescription(data.description);
    });
  }, [tag]);

  const navigate = useNavigate();

  const openEditDrawer = (dm: DrawerModel) => {
    setIsDrawerOpen(true);
    setDrawerData(dm);
  };

  const handleEditField = (setEditFieldData: (data: string) => void) => {
    setEditFieldData(editField);
    setIsDrawerOpen(false);
  };

  const handleSpeciesChange = (event: SelectChangeEvent) => {
    setSpecies(event.target.value as string);

    if (event.target.value === "Gato") {
      get("/breeds/cat")
        .then((breedsData) => {
          const r = breedsData.map((b: BreedModel) => ({
            label: `${b.breed}`
          }));
          r.unshift({
            label: "Mestizo"
          });
          setBreeds(r);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }

    if (event.target.value === "Perro") {
      get("/breeds/dog")
        .then((breedsData) => {
          const r = breedsData.map((b: BreedModel) => ({
            label: `${b.breed}`
          }));
          r.unshift({
            label: "Mestizo"
          });
          setBreeds(r);
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  };

  const handleBreedChange = (event: any, selectedBreed: string | null) => {
    const breed = selectedBreed as unknown as { label: string };
    setBreed(breed.label);
  };

  const handleSterilizationChange = (event: SelectChangeEvent) => {
    console.log(event.target.value);
    setSterilized(event.target.value === "Sí");
  };
  const handleShowContact = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    setShow_contact(event.target.checked ?? false);
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageSelectedName(e.target.files![0]?.name);
    setIsImageSelected(e.target.files![0] !== undefined);

    const imageKitAuth = await get("/imagekit/auth");
    const { signature, expire, token } = imageKitAuth;

    setUploadOptions({
      file: e.target.files![0],
      fileName: e.target.files![0]?.name,
      token,
      signature,
      expire
    } as UploadOptions);
  };

  const handleDeletePetDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeletePet = () => {
    del(`/pets/${tag}`).then(() => {
      navigate("/mascotas");
    }).catch((err) => {
      console.log(err);
    });
  };

  const handleSavePetInfo = () => {
    if (isImageSelected) {
      const imagekit = new ImageKit(
        {
          publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY as string,
          urlEndpoint: process.env.REACT_APP_IMAGEKIT_ENDPOINT as string
        }
      );
      imagekit.upload(
        uploadOptions as UploadOptions
      ).then((res: any) => {
        const petInfo = {
          show_contact,
          name,
          birthdate,
          sex,
          species,
          breed,
          sterilized,
          photo: res.url,
          description,
          lost: "no"
        };
        put(`/pets/${tag}`, petInfo).then(() => setIsConfirmationOpen(true));
        console.log(res);
      }).catch((err: any) => {
        console.log("caught ", err);
      });
      return;
    }

    const petInfo = {
      show_contact,
      name,
      birthdate,
      sex,
      species,
      breed,
      sterilized,
      description,
      lost: "no"
    };
    put(`/pets/${tag}`, petInfo).then(() => setIsConfirmationOpen(true));
    return;
  };

  return (
    <Box margin={2}>
      <Grid
        container
        spacing={1}
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        minHeight="75vh"
      >
        <Grid item mb={2}>
          <Typography variant="h6">
            Información de la mascota
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            Esta información se mostrará únicamente en el perfil de tu mascota.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Mostrar información de contacto
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <Switch checked={show_contact} onChange={handleShowContact} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={1}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            Únicamente muestra tu información de contacto cuando tu mascota esté
            perdida.
          </Typography>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Nombre o apodo
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  openEditDrawer({
                      title: "Edita el nombre o apodo",
                      instruction: "Ingresa el nombre o apodo para tu mascota.",
                      data: name,
                      setFieldData: setName
                    }
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Fecha de nacimiento o adopción (día/mes/año)
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  openEditDrawer({
                      title: "Fecha de nacimiento o adopción (día/mes/año)",
                      instruction: "Ingresa la fecha de nacimiento o adopción",
                      data: birthdate,
                      setFieldData: setBirthdate
                    }
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={1}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {birthdate}
          </Typography>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Sexo
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  openEditDrawer({
                    title: "Sexo",
                    instruction: "Edita el sexo de tu mascota.",
                    data: sex,
                    setFieldData: setSex
                  })
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={1}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {sex}
          </Typography>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item mt={1}>
          <FormControl size="small" fullWidth>
            <InputLabel id="species-select-label">Especie</InputLabel>
            <Select
              labelId="species-select-label"
              id="species-select"
              label="Especie"
              value={species}
              onChange={handleSpeciesChange}
              fullWidth
            >
              <MenuItem value=""> <em>Elegir</em> </MenuItem>
              <MenuItem value={species}> {species} </MenuItem>
              {species === "Gato" && <MenuItem value={"Perro"}>Perro</MenuItem>}
              {species === "Perro" && <MenuItem value={"Gato"}>Gato</MenuItem>}
            </Select>
          </FormControl>
        </Grid>
        <Grid item mt={1}>
          <Autocomplete
            id="register-breed"
            disablePortal
            options={breeds}
            renderInput={(params) => <TextField {...params} label="Raza" />}
            size="small"
            fullWidth
            componentsProps={{ popper: { style: { width: "fit-content" } } }}
            onChange={handleBreedChange}
            value={breed}
          />
        </Grid>
        <Grid item mt={1}>
          <FormControl size="small" fullWidth>
            <InputLabel id="sterilization-select-label" shrink={true}>¿Se encuentra esterilizado?</InputLabel>
            <Select
              labelId="sterilization-select-label"
              id="sterilization-select"
              label="¿Se encuentra esterilizado?"
              value={sterilized ? "Sí" : "No"}
              onChange={handleSterilizationChange}
              fullWidth
            >
              {!sterilized && <MenuItem value={"Sí"}>Sí</MenuItem>}
              {sterilized && <MenuItem value={"No"}>No</MenuItem>}
              <MenuItem value={sterilized ? "Sí" : "No"}>{sterilized ? "Sí" : "No"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {isImageSelected &&
          < Grid item>
            <Chip color="success" label={imageSelectedName}></Chip>
          </Grid>
        }
        <Grid item mt={"5px"}>
          <Button
            disableElevation
            component="label"
            variant="contained"
            style={{ textTransform: "none" }}
            startIcon={<CloudUploadIcon style={{ color: "white" }} />}
            fullWidth
          >
            Actualizar la fotografía de tu mascota
            <input type="file" onChange={handleImageUpload} accept={".jpeg, .jpg, .png"} hidden />
          </Button>
        </Grid>
        <Grid item mt={"5px"} mb={"5px"} alignContent="start">
          <TextField
            id="add-pet-description"
            label="Descripción"
            variant="outlined"
            size="small"
            multiline={true}
            minRows={5}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item width="100%">
          <Button
            disableElevation
            component="label"
            variant="contained"
            sx={{ textTransform: "none" }}
            fullWidth
            onClick={handleSavePetInfo}
          >
            Guardar
          </Button>
        </Grid>
        <Grid item width="100%">
          <Button
            disableElevation
            sx={{ textTransform: "none" }}
            fullWidth
            onClick={handleDeletePetDialog}
          >
            Eliminar mascota
          </Button>
        </Grid>
        <Grid item mt={2} width="100%">
          <Collapse in={isConfirmationOpen}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsConfirmationOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 1 }}
            >
              Los cambios fueron guardados.
            </Alert>
          </Collapse>
        </Grid>
      </Grid>

      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box margin={2}>
          <Grid
            container
            spacing={0.8}
            direction="column"
            alignItems="center"
            justifyContent="center"
            minWidth="80vw"
          >
            <Grid item textAlign="center" width="100%">
              <Typography variant="body1">
                {drawerData.title}
              </Typography>
            </Grid>
            <Grid item width="100%">
              <Typography align="justify" fontSize="0.85rem" color="gray">
                {drawerData.instruction}
              </Typography>
            </Grid>
            <Grid item width="100%">
              <TextField
                id="drawer-field"
                label=""
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={drawerData.data}
                onChange={(e) => setEditField(e.target.value)}
              />
            </Grid>
            <Grid item width="100%" marginTop={2}>
              <Button
                disableElevation
                component="label"
                variant="contained"
                sx={{ textTransform: "none" }}
                fullWidth
                onClick={() => handleEditField(drawerData.setFieldData)}
              >
                Editar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Eliminar mascota?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Quieres eliminar el perfil de esta mascota?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setIsDeleteDialogOpen(false);
          }}>Cancelar</Button>
          <Button onClick={handleDeletePet} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );

}

export default PetInfo;
