import {
  Alert, Autocomplete,
  Button,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { get } from "../../services/fetchers";
import { CityModel } from "../../models/city.model";

function Register() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [email, setEmail] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [cities, setCities] = useState([]);

  const [errorAlertMessage, setMessage] = useState("");

  useEffect(() => {
    get("/cities")
      .then((citiesData) => {
        // setCities(citiesData.map((c: CityModel) => <MenuItem key={`${c.province} - ${c.city}`}
        //                                                      value={c.city}> {c.city} </MenuItem>));
        setCities(citiesData.map((c: CityModel) => ({
          label: `${c.province} - ${c.city}`
        })));
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, []);

  async function registerUser(formInfo: any) {
    fetch(process.env.REACT_APP_RINGO_API_HOST + "/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(formInfo),
      credentials: "include"
    })
      .then((response) => {
        switch (response.status) {
          case 201:
            navigate("/");
            break;

          case 409:
            setMessage("Un usuario con ese email ya existe.");
            setOpen(true);
            break;

          default:
            break;
        }
        return response.json(); // important in order to be able to read data in further then
      })
      .then((data) => {
        console.log("data :>> ", data);
      })
      .catch((err) => {
        console.log("err :>> ", err);
        setMessage(
          "Hubo un problema con el registro del usuario. Por favor inténtelo más tarde."
        );
        setOpen(true);
      });

    return;
  }

  const handleRegistration = async () => {
    if (password !== passwordConfirmation) {
      setMessage("Las contraseñas no coinciden.");
      setOpen(true);
    }
    if (email !== emailConfirmation) {
      setMessage("Los emails no coinciden.");
      setOpen(true);
    }
    await registerUser({
      firstName,
      lastName,
      email,
      city,
      password
    });
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
    >
      <Grid item width="80%" mb={2}>
        <Typography variant="h6">
          Crea tu cuenta
        </Typography>
      </Grid>
      <Grid item>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorAlertMessage}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item mt={2.5} width="80%">
        <TextField
          id="register-email"
          label="Correo electrónico"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <TextField
          id="register-email-confirmation"
          label="Confirma tu correo electrónico"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setEmailConfirmation(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <TextField
          id="register-name"
          label="Nombre"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <TextField
          id="register-surname"
          label="Apellido"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <Autocomplete
          id="register-city"
          disablePortal
          options={cities}
          renderInput={(params) => <TextField {...params} label="Ciudad" />}
          size="small"
          fullWidth
          componentsProps={{ popper: { style: { width: "fit-content" } } }}
          onChange={(event: any, selectedCity: string | null) => {
            const city = selectedCity as unknown as { label: string };
            setCity(city.label);
          }}
        />
      </Grid>
      <Grid item width="80%">
        <TextField
          id="register-pd"
          label="Contraseña"
          variant="outlined"
          type="password"
          size="small"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
          inputProps={{
            autocomplete: "new-password",
            form: {
              autocomplete: "off"
            }
          }}
        />
      </Grid>
      <Grid item width="80%">
        <TextField
          id="register-password-confirmation"
          label="Confirma tu contraseña"
          variant="outlined"
          type="password"
          size="small"
          fullWidth
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          variant="contained"
          style={{ textTransform: "none" }}
          fullWidth
          onClick={handleRegistration}
        >
          Crear cuenta
        </Button>
      </Grid>
      <Grid item mt={2.5}>
        <Typography variant="subtitle2">
          ¿Ya tienes una cuenta?{" "}
          <Link to="/" style={{ textDecoration: "none" }}>
            Inicia sesión aquí.
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Register;
