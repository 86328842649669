import { Avatar, Box, Grid, IconButton } from "@mui/material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardHeader from "@mui/material/CardHeader";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import { PetModel } from "../../models/pet.model";

function PetCard({ pet }: { pet: PetModel }) {
  const name = pet.name;
  const tag = pet.tag;

  return (
    <Box margin={2}>
      <Card elevation={0} sx={{ backgroundColor: "cards.main" }}>
        <CardActionArea component={Link} to={`/mascotas/${tag}`}>
          <CardHeader
            avatar={
              <Avatar
                aria-label="pet"
                src={pet.photo}
              />
            }
            action={
              <IconButton aria-label="settings">
                <Link to={`/mascotas/${tag}/editar`}>
                  <EditIcon />
                </Link>
              </IconButton>
            }
          />
          <Box marginY={2} marginX={3} sx={{ textAlign: "start" }}>
            <Grid container alignContent="start">
              <Grid item xs={6} textAlign="start">
                {name}
              </Grid>
              <Grid item xs={6} textAlign="end">
                {tag.toUpperCase()}
              </Grid>
            </Grid>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default PetCard;
