import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import * as React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { MuiTelInput } from "mui-tel-input";
import { get, post, put } from "../../services/fetchers";
import ContactCard from "./contactCard";
import { ContactModel } from "../../models/contact.model";

type action = "register" | "edit";

function ContactInfo() {
  const [contacts, setContacts] = useState([]);

  const refreshContactData = () => {
    get("/contacts")
      .then((contactsData) => {
        setContacts(contactsData.map((contact: ContactModel) => <ContactCard key={contact.name} contact={contact}
                                                                             handleOpenDrawer={handleOpenDrawer}
                                                                             refreshContactData={refreshContactData} />));
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };
  useEffect(() => {
    refreshContactData();
  }, []);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({
    title: "",
    instruction: "",
    contactData: {} as ContactModel,
    action: ""
  });

  const handleOpenDrawer = (title: string, instruction: string, contactData: ContactModel, action: action) => {
    setIsDrawerOpen(true);
    setDrawerData({ title, instruction, contactData, action });
  };

  const saveContact = () => {
    if (drawerData.action === "register") {
      post("/contacts", drawerData.contactData).then(() => {
        setIsDrawerOpen(false);
        refreshContactData();
      });
    }

    if (drawerData.action === "edit") {
      put(`/contacts/${drawerData.contactData.id}`, drawerData.contactData).then(() => {
        setIsDrawerOpen(false);
        refreshContactData();
      });
    }
  };

  return (
    <Box margin={2}>
      <Grid
        container
        spacing={0.8}
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        minHeight="75vh"
      >
        <Grid item mb={2}>
          <Typography variant="h6">
            Información de contacto
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" align="justify" color="gray">
            Esta información de contacto se mostrará en todos los perfiles de
            tus mascotas.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center" mt={1}>
            <Grid item xs={11} display="flex" justifyContent="stretch">
              <Typography variant="body2">
                Responsables
              </Typography>
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                data-testid="AddPetIconButton"
                size="large"
                edge="end"
                color="primary"
                aria-label="menu"
                onClick={() =>
                  handleOpenDrawer(
                    "Registra un nuevo contacto",
                    "Ingresa la información de contacto",
                    {} as ContactModel,
                    "register"
                  )
                }
              >
                <AddCircleIcon
                  sx={{
                    boxSizing: "content-box",
                    color: "black"
                  }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          {contacts}
        </Grid>
      </Grid>

      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box margin={2}>
          <Grid
            container
            spacing={0.8}
            direction="column"
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item textAlign="center">
              <Typography variant="body1">
                {drawerData.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="justify" fontSize="0.85rem" color="gray">
                {drawerData.instruction}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="contact-name"
                label="Nombre completo"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={drawerData.contactData.name}
                onChange={(e) => setDrawerData({
                  ...drawerData,
                  contactData: { ...drawerData.contactData, name: e.target.value }
                })}
              />
            </Grid>
            <Grid item>
              <TextField
                id="contact-title"
                label="Título (Papá, Mamá, tutor)"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={drawerData.contactData.nickname}
                onChange={(e) => setDrawerData({
                  ...drawerData,
                  contactData: { ...drawerData.contactData, nickname: e.target.value }
                })}
              />
            </Grid>
            <Grid item>
              <MuiTelInput
                value={drawerData.contactData.phone ? drawerData.contactData.phone : "+593"}
                onChange={(e) => setDrawerData({ ...drawerData, contactData: { ...drawerData.contactData, phone: e } })}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item>
              <TextField
                id="contact-instagram"
                label="Instagram"
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={drawerData.contactData.instagram_account}
                onChange={(e) => setDrawerData({
                  ...drawerData,
                  contactData: { ...drawerData.contactData, instagram_account: e.target.value }
                })}
              />
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Button
              disableElevation
              component="label"
              variant="contained"
              sx={{ width: "100%", textTransform: "none" }}
              onClick={saveContact}
            >
              {drawerData.action === "register" ? "Registrar" : "Editar"}
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

export default ContactInfo;
