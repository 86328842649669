import * as React from "react";
import { Box, Button, CircularProgress, MobileStepper } from "@mui/material";
import Step1 from "./steps/step1";
import Step2 from "./steps/step2";
import Step3 from "./steps/step3";
import Step4 from "./steps/step4";
import { useState } from "react";
import { PetModel } from "../../models/pet.model";
import { get, post } from "../../services/fetchers";
import ImageKit from "imagekit-javascript";
import { UploadOptions } from "imagekit-javascript/dist/src/interfaces";

function AddPet() {
  const [tag, setTag] = useState("");
  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [sex, setSex] = useState("");
  const [species, setSpecies] = useState("");
  const [breed, setBreed] = useState("");
  const [sterilized, setSterilized] = useState(false);
  const [description, setDescription] = useState("");

  const [activeStep, setActiveStep] = useState(1);

  const [isTagInvalid, setIsTagInvalid] = useState(false);

  const [uploadOptions, setUploadOptions] = useState({} as UploadOptions);

  const [waiting, setWaiting] = useState(false);

  const handleNext = async () => {
    if (activeStep === 1) {
      const petData = await get(`/pets/${tag}`).catch(() => {
        console.log("No pet found with the provided tag");
      });
      if (petData) {
        console.log("ESTE TAG YA EXISTE EN BDD");
        setIsTagInvalid(true);
        return;
      }
    }
    setActiveStep((step) => step + 1);
  };

  const handleBack = () => {
    setActiveStep((step) => step - 1);
  };
  const addPet = async () => {
    setWaiting(true);
    const imagekit = new ImageKit(
      {
        publicKey: process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY as string,
        urlEndpoint: process.env.REACT_APP_IMAGEKIT_ENDPOINT as string
      }
    );
    imagekit.upload(
      uploadOptions as UploadOptions
    ).then((res: any) => {
      const newPet = {
        tag,
        name,
        birthdate,
        sex,
        species,
        breed,
        sterilized,
        photo: res.url,
        description,
        lost: "no"
      } as PetModel;
      post("/pets", newPet).then(() => {
        setWaiting(false);
        handleNext();
      }).catch((err) => {
        setWaiting(false);
        console.log("error:", err);
      });
      console.log(res);
    }).catch((err: any) => {
      setWaiting(false);
      console.log("caught ", err);
    });
  };

  if (waiting) {
    return (<CircularProgress />);
  }

  return (
    <Box alignItems="center" mt={1}>
      {activeStep === 1 && <Step1 activeStep={activeStep} tag={tag}
                                  setTag={setTag}
                                  isTagInvalid={isTagInvalid}
                                  setIsTagInvalid={setIsTagInvalid} />}
      {activeStep === 2 && <Step2 activeStep={activeStep}
                                  name={name}
                                  setName={setName}
                                  birthdate={birthdate}
                                  setBirthdate={setBirthdate}
                                  sex={sex}
                                  setSex={setSex}
                                  species={species}
                                  setSpecies={setSpecies}
                                  breed={breed}
                                  setBreed={setBreed}
                                  sterilized={sterilized}
                                  setSterilized={setSterilized}
                                  setUploadOptions={setUploadOptions} />}
      {activeStep === 3 && <Step3 activeStep={activeStep}
                                  description={description}
                                  setDescription={setDescription}
                                  addPet={addPet} />}
      {activeStep === 4 && <Step4 activeStep={activeStep} petName={name} />}
      <Box m={2}>
        {activeStep <= 3 &&
          <MobileStepper
            variant="progress"
            steps={5}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                disabled={activeStep >= 3}
                disableElevation
                size="small"
                onClick={handleNext}
                sx={{ border: "none" }}
              >
                {activeStep < 3 &&
                  <img
                    src={process.env.PUBLIC_URL + "/images/siguiente.svg"}
                    alt="paw" width={35}
                  />
                }
              </Button>
            }
            backButton={
              <Button
                disabled={activeStep === 1}
                disableElevation
                size="small"
                onClick={handleBack}
                sx={{ border: "none" }}
              >
                {activeStep !== 1 &&
                  <img
                    src={process.env.PUBLIC_URL + "/images/atras.svg"}
                    alt="paw" width={35}
                  />
                }
              </Button>
            }
          />
        }
      </Box>
    </Box>
  );
}

export default AddPet;
