import {
  Box, Button,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CallIcon from "@mui/icons-material/Call";
import InstagramIcon from "@mui/icons-material/Instagram";
import * as React from "react";
import { ContactModel } from "../../models/contact.model";
import { del } from "../../services/fetchers";

function ContactCard(props: any) {
  const handleOpenDrawer = props.handleOpenDrawer;
  const refreshContactData = props.refreshContactData;
  const contact: ContactModel = props.contact;

  const [isDeleteContactDialogOpen, setIsDeleteContactDialogOpen] = React.useState(false);

  const handleOpenDeleteContactDialog = () => {
    setIsDeleteContactDialogOpen(true);
  };
  const handleCloseDeleteContactDialog = () => {
    setIsDeleteContactDialogOpen(false);
  };

  const deleteContact = (id: string) => {
    del(`/contacts/${id}`).then(() => {
      refreshContactData();
    });
  };

  return (
    <Box>
      <Divider variant="fullWidth" />
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11} display="flex" justifyContent="stretch">
            <Typography variant="body2">
              {contact.name}
            </Typography>
          </Grid>
          <Grid item xs={1} justifyContent="flex-end" display="flex">
            <IconButton
              size="large"
              edge="end"
              aria-label="menu"
              onClick={() =>
                handleOpenDrawer(
                  "Edita el contacto",
                  "Ingresa la información de contacto",
                  contact,
                  "edit"
                )
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              data-testid="DeleteContactButton"
              size="large"
              edge="end"
              aria-label="menu"
              onClick={handleOpenDeleteContactDialog}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item marginBottom={2}>
        <List disablePadding={true}>
          <ListItem disablePadding={true}>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <BookmarkBorderIcon />
            </ListItemIcon>
            <ListItemText secondary={contact.nickname} />
          </ListItem>
          <ListItem disablePadding={true}>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <CallIcon />
            </ListItemIcon>
            <ListItemText secondary={contact.phone} />
          </ListItem>
          <ListItem disablePadding={true}>
            <ListItemIcon style={{ minWidth: "40px" }}>
              <InstagramIcon />
            </ListItemIcon>
            <ListItemText secondary={contact.instagram_account} />
          </ListItem>
        </List>
      </Grid>
      <Dialog
        open={isDeleteContactDialogOpen}
        onClose={handleCloseDeleteContactDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Borrar contacto"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Borrar este contacto?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteContactDialog}>Cancelar</Button>
          <Button onClick={() => deleteContact(contact.id)} autoFocus>
            Borrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ContactCard;