import { Box, Grid } from "@mui/material";
import * as React from "react";

export default function FooterAppBar() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      mb={1}
    >
      <Grid item>
        <img
          src={process.env.PUBLIC_URL + "/images/ringo-vertical.svg"}
          alt="paw" width={65}
        />
      </Grid>
      <Grid item>
        <Box sx={{ fontSize: "12px", color: "gray" }}>
          © 2024 Ringo. Todos los derechos reservados.
        </Box>
      </Grid>
    </Grid>
  );
}
