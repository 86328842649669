import { Button, Grid, Typography, TextField } from "@mui/material";

function Step3(props: any) {
  const description = props.description;
  const setDescription = props.setDescription;

  return (
    <Grid
      container
      spacing={"15px"}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="60vh"
    >
      <Grid item width="80%">
        <Typography variant="h6">
          Agrega una mascota
        </Typography>
      </Grid>
      <Grid item margin={3} width="80%">
        <Typography
          align="justify"
          fontSize="0.85rem"
          color="gray"
          textAlign="justify"
          width="100%"
        >
          Menciona aquellos rasgos que diferencian a tu mascota de otras.
          También puedes incluir sus enfermedades o medicamentos.
        </Typography>
      </Grid>
      <Grid item width="80%" alignContent="start">
        <TextField
          id="add-pet-description"
          label="Descripción"
          variant="outlined"
          size="small"
          multiline={true}
          minRows={5}
          fullWidth
          onChange={(e) => setDescription(e.target.value)}
          value={description}
        />
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          component="label"
          variant="contained"
          style={{ textTransform: "none" }}
          fullWidth
          onClick={props.addPet}
        >
          Finalizar registro
        </Button>
      </Grid>
    </Grid>
  );
}

export default Step3;
