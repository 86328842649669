import {
  Alert,
  Button, CircularProgress,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { post } from "../../services/fetchers";

function Login() {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [waiting, setWaiting] = useState(false);

  async function loginUser(credentials: any) {
    return post("/login", credentials)
      .then((data: any) => {
        setWaiting(false);
        localStorage.setItem("isUserLoggedIn", data.message);
        navigate("/mascotas");
      })
      .catch((err) => {
        setWaiting(false);
        console.log("error:", err);
        setOpen(true);
        setErrorMessage("Error de inicio de sesión.");
      });
  }

  const handleLogin = async () => {
    setWaiting(true);
    await loginUser({
      email,
      password
    });
  };

  if (waiting) {
    return (<CircularProgress />);
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="85vh"
    >
      <Grid item>
        <img
          src={process.env.PUBLIC_URL + "/images/collar.svg"}
          alt="ringo logo"
          style={{ maxWidth: 120 }}
        />
      </Grid>
      <Grid item mt={2.5}>
        <Typography variant="h4" letterSpacing={-2}>
          Bienvenido/a
        </Typography>
      </Grid>
      <Grid item>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item mt={2.5} width="80%">
        <TextField
          id="login-email"
          label="Correo electrónico"
          variant="outlined"
          size="small"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <TextField
          id="login-password"
          label="Contraseña"
          variant="outlined"
          type="password"
          size="small"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          variant="contained"
          style={{ width: "100%", textTransform: "none" }}
          fullWidth
          onClick={handleLogin}
        >
          Iniciar sesión
        </Button>
      </Grid>
      <Grid item mt={2.5}>
        <Typography variant="subtitle2">
          ¿No tienes una cuenta?{" "}
          <Link to="/registrar" style={{ textDecoration: "none" }}>
            Crear una ahora.
          </Link>
        </Typography>
        {/*<Typography variant="subtitle2">*/}
        {/*  ¿Olvidaste tu contraseña?{" "}*/}
        {/*  <Link to="/restablecer" style={{ textDecoration: "none" }}>*/}
        {/*    Restablécela aquí.*/}
        {/*  </Link>*/}
        {/*</Typography>*/}
      </Grid>
    </Grid>
  );
}

export default Login;
