import {
  Alert,
  Box,
  Button,
  Checkbox, Collapse,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import * as React from "react";
import { get, put } from "../../services/fetchers";
import { UserModel } from "../../models/user.model";
import CloseIcon from "@mui/icons-material/Close";


function AccountSettings() {

  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [emailOffersAllowed, setEmailOffersAllowed] = useState(false);
  const [password, setPassword] = useState("");

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const getUserData = () => {
    get("/users")
      .then((userData: UserModel) => {
        setId(userData.id);
        setEmail(userData.email);
        setFirstName(userData.firstName);
        setLastName(userData.lastName);
        setPhone(userData.phone);
        setEmailOffersAllowed(userData.emailOffersAllowed);
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  };
  useEffect(() => {
    getUserData();
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerData, setDrawerData] = useState({
    title: "",
    instruction: "",
    data: "",
    setter: (data: string) => {
    }
  });

  const handleOpenDrawer = (title: string, instruction: string, data: string, setter: (data: string) => void) => {
    setDrawerOpen(true);
    setDrawerData({ title, instruction, data, setter });
  };
  const handleEdit = () => {
    setDrawerOpen(false);
  };
  const handleSaveSettings = () => {
    if (password !== "") {
      put(`/users/${id}/password`, { password }).then();
    }
    const userInfo = {
      firstName,
      lastName,
      email,
      emailOffersAllowed,
      phone
    } as UserModel;
    put(`/users/${id}`, userInfo).then(() => {
      getUserData();
      setIsConfirmationOpen(true);
    });

  };
  return (
    <Box margin={2}>
      <Grid
        container
        spacing={0.8}
        direction="column"
        alignItems="strecht"
        justifyContent="center"
        minHeight="75vh"
      >
        <Grid item mb={2}>
          <Typography variant="h6">
            Ajustes
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            Edita tu cuenta y los ajustes de seguridad.
          </Typography>
        </Grid>
        <Grid item marginY={2} textAlign="start">
          Cuenta
        </Grid>
        <Divider variant="fullWidth" />
        <Grid item>
          <Grid container mt={1} alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Correo electrónico
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  handleOpenDrawer(
                    "Edita el correo electrónico",
                    "Ingresa tu correo electrónico.",
                    email,
                    setEmail
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={2}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {email}
          </Typography>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid item>
          <Grid container mt={1} alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Nombre
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  handleOpenDrawer(
                    "Edita tu nombre",
                    "Ingresa tu nombre",
                    firstName,
                    setFirstName
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={2}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {firstName}
          </Typography>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid item>
          <Grid container mt={1} alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Apellido
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  handleOpenDrawer(
                    "Edita tu apellido",
                    "Ingresa tu apellido",
                    lastName,
                    setLastName
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={2}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {lastName}
          </Typography>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid item>
          <Grid container mt={1} alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Teléfono
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  handleOpenDrawer(
                    "Edita tu número de teléfono",
                    "Ingresa tu número de teléfono.",
                    phone,
                    setPhone
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={2}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            {phone}
          </Typography>
        </Grid>
        <Divider variant="fullWidth" />
        <Grid item>
          <Grid container mt={1} alignItems="center">
            <Grid item xs={11} display="flex" justifyContent="stretch">
              Contraseña
            </Grid>
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <IconButton
                onClick={() =>
                  handleOpenDrawer(
                    "Edita tu contraseña.",
                    "Ingresa tu contraseña.",
                    password,
                    setPassword
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item marginBottom={2}>
          <Typography align="justify" fontSize="0.85rem" color="gray">
            *********
          </Typography>
        </Grid>
      </Grid>
      <Collapse in={isConfirmationOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setIsConfirmationOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Los cambios fueron guardados.
        </Alert>
      </Collapse>
      <Box marginTop={1}>
        <Button
          disableElevation
          component="label"
          variant="contained"
          sx={{ textTransform: "none" }}
          fullWidth
          onClick={handleSaveSettings}
        >
          Guardar
        </Button>
      </Box>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box margin={2}>
          <Grid
            container
            spacing={0.8}
            direction="column"
            alignItems="strecht"
            justifyContent="center"
          >
            <Grid item textAlign="center">
              <Typography variant="body1">
                {drawerData.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography align="justify" fontSize="0.85rem" color="gray">
                {drawerData.instruction}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                id="drawer-field"
                label=""
                variant="outlined"
                size="small"
                fullWidth
                defaultValue={drawerData.data}
                onChange={(e) => drawerData.setter(e.target.value)}
              />
            </Grid>
            {drawerData.setter === setEmail && (
              <Grid item>
                <FormControlLabel
                  control={<Checkbox defaultChecked onChange={(e) => setEmailOffersAllowed(e.target.checked)} />}
                  label={
                    <Typography align="justify" fontSize="0.85rem" color="gray">
                      Permitir a Ringo enviarte alertas a tu correo electrónico
                      cuando la placa de tu mascota sea escaneada.
                    </Typography>
                  }
                />
              </Grid>
            )}
          </Grid>
          <Box marginTop={2}>
            <Button
              disableElevation
              component="label"
              variant="contained"
              sx={{ textTransform: "none" }}
              fullWidth
              onClick={handleEdit}
            >
              Editar
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

export default AccountSettings;
