export const get = async (endpoint: string) => {
  return fetch(process.env.REACT_APP_RINGO_API_HOST + endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  }).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`An error occurred calling '${endpoint}' with GET method.`);
      }
      return response.json();
    }
  );
};

export const post = async (endpoint: string, body: any) => {
  return fetch(process.env.REACT_APP_RINGO_API_HOST + endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
    credentials: "include"
  }).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`An error occurred calling '${endpoint}' with POST method.`);
      }
      return response.json();
    }
  );
};

export const put = async (endpoint: string, body: any) => {
  return fetch(process.env.REACT_APP_RINGO_API_HOST + endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body),
    credentials: "include"
  }).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`An error occurred calling '${endpoint}' with PUT method.`);
      }
      return response.json();
    }
  );
};

export const del = async (endpoint: string) => {
  return fetch(process.env.REACT_APP_RINGO_API_HOST + endpoint, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  }).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`An error occurred calling '${endpoint}' with DELETE method.`);
      }
      return response.json();
    }
  );
};