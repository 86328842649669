import { Button, Grid, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Reset() {
  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      minHeight="90vh"
    >
      <Grid item mt={2.5} width="80%" mb={2}>
        <Typography variant="h6">
          Restablece tu contraseña
        </Typography>
      </Grid>
      <Grid item margin={2} width="80%">
        <Typography
          align="justify"
          fontSize="0.65rem"
          color="gray"
          width="100%"
        >
          Ingresa el correo electrónico de tu cuenta. Enviaremos un email con
          los pasos para restablecer tu contraseña. Por favor, asegúrate de
          revisar la carpeta de spam.
        </Typography>
      </Grid>
      <Grid item width="80%">
        <TextField
          id="reset-email"
          label="Confirma tu correo electrónico"
          variant="outlined"
          size="small"
          fullWidth
        />
      </Grid>
      <Grid item width="80%">
        <Button
          disableElevation
          variant="contained"
          style={{ textTransform: "none" }}
          fullWidth
        >
          Restablecer contraseña
        </Button>
      </Grid>
      <Grid item mt={2.5} width="80%">
        <Typography variant="subtitle2">
          ¿Tienes inconvenientes?{" "}
          <Link to="https://wa.me/593997265507" target="_blank" style={{ textDecoration: "none" }}>
            Contáctanos aquí.
          </Link>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Reset;
