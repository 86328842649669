import { Box, CircularProgress, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PetCard from "./card";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { PetModel } from "../../models/pet.model";
import { get } from "../../services/fetchers";

function Pets() {
  const [petCards, setPetCards] = useState([]);
  useEffect(() => {
    get("/pets")
      .then((petsData) => {
        setPetCards(petsData.map((pet: PetModel) => <PetCard pet={pet} />));
        setWaiting(false);
      })
      .catch((err) => {
        setWaiting(false);
        console.log("err :>> ", err);
      });
  }, []);

  const [waiting, setWaiting] = useState(true);

  if (waiting) {
    return (<CircularProgress />);
  }

  return (
    <Box minHeight="85vh">
      <Toolbar>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" mt={2}>
          <Grid item>
            <Typography variant="h6">
              Mascotas
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              data-testid="AddPetIconButton"
              size="large"
              edge="end"
              color="primary"
              aria-label="menu"
              component={Link}
              to="/mascotas/agregar"
            >
              <AddCircleIcon
                sx={{
                  boxSizing: "content-box",
                  color: "black"
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <>
        {petCards}
      </>
    </Box>
  );
}

export default Pets;
