import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import AppMenu from "./menu";

export default function HeaderAppBar() {

  const location = useLocation();
  return (
    <AppBar
      position="static"
      style={{ background: "transparent", boxShadow: "none" }}
    >
      <Toolbar>
        <Grid container mt={1} alignItems="center">
          <Grid item xs={11} display="flex" justifyContent="stretch">
            <Link to="/mascotas">
              <img
                src={process.env.PUBLIC_URL + "/images/ringo-horizontal.svg"}
                alt="ringo logo"

                style={{ maxWidth: 100 }}
              />
            </Link>
          </Grid>
          {(location.pathname !== "/" && location.pathname !== "/registrar") &&
            <Grid item xs={1} justifyContent="flex-end" display="flex">
              <AppMenu />
            </Grid>
          }
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
