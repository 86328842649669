import { Box, CircularProgress } from "@mui/material";
import PetRecord from "./record";
import { useParams } from "react-router-dom";
import { get, post } from "../../services/fetchers";
import { useEffect, useState } from "react";
import NoMatch from "../nomatch";

function PetFile() {
  const { id } = useParams();
  const [foundPet, setFoundPet] = useState({});
  const [found, setFound] = useState(false);
  const [waiting, setWaiting] = useState(true);
  useEffect(() => {
    get(`/found/${id}`)
      .then((foundPetData) => {
        setFoundPet(foundPetData);
        setWaiting(false);
        if (foundPetData.tag !== "") {
          setFound(true);
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
        setWaiting(false);
      });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          post("/email", {
            id,
            latitude,
            longitude
          }).then((r) => console.log(r.message));
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      // if geolocation is not supported by the users browser
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);


  if (waiting) {
    return (<CircularProgress />);
  }

  if (!found) {
    return (<NoMatch />);
  }

  return (
    <Box margin={2}>
      <PetRecord foundPet={foundPet} />
    </Box>
  );
}

export default PetFile;
