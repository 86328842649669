import {
  Avatar,
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, Stack,
  Typography
} from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PlaceIcon from "@mui/icons-material/Place";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import Card from "@mui/material/Card";
import { ContactModel } from "../../models/contact.model";
import ContactCallToActions from "../contact/contactCallToActions";

function PetRecord(props: any) {
  const foundPet = props.foundPet;
  return (
    <Box>
      <Grid container justifyContent="center" mb={3}>
        <Avatar
          aria-label="pet"
          src={foundPet.photo}
          sx={{ width: 300, height: 300 }}
        />
      </Grid>
      <Box marginBottom={2}>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item mb={2}>
            <Typography variant="h6">
              {foundPet.name}
            </Typography>
          </Grid>
          <Grid item>
            <List>
              <ListItem disablePadding={true}>
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <QrCodeIcon />
                </ListItemIcon>
                <ListItemText secondary={foundPet.tag.toUpperCase()} />
              </ListItem>
              <ListItem disablePadding={true}>
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <PlaceIcon />
                </ListItemIcon>
                <ListItemText secondary={foundPet.city} />
              </ListItem>
              <ListItem disablePadding={true}>
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <ListItemText secondary={foundPet.birthdate} />
              </ListItem>
              <ListItem disablePadding={true}>
                <ListItemIcon style={{ minWidth: "40px" }}>
                  <NoteAddIcon />
                </ListItemIcon>
                <ListItemText secondary={foundPet.sterilized ? "Esterilizado" : "No esterilizado"} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Box>
      <Divider variant="fullWidth" />
      <Box my={2}>
        <List component={Stack} direction="row" spacing={7}>
          <ListItem disablePadding={true}>
            <ListItemIcon>
              <img
                src={process.env.PUBLIC_URL + "/images/raza-perfil.svg"}
                alt="paw" width={40}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography>
                {foundPet.breed}
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem disablePadding={true}>
            <ListItemIcon>
              <img
                src={process.env.PUBLIC_URL + "/images/genero-perfil.svg"}
                alt="sex" width={40}
              />
            </ListItemIcon>
            <ListItemText>
              <Typography>{foundPet.sex === "M" ? "Macho" : "Hembra"}</Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Box>
      <Box>
        <Card elevation={0}
              sx={{
                background: `url(${process.env.PUBLIC_URL + "/images/fondo-descripcion-perfil.svg"})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
              }}>
          <CardContent>
            <Typography
              color="text.secondary"
              textAlign="center">
              {foundPet.description}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box>
        {foundPet.show_contact ? foundPet.contacts.map((contact: ContactModel) => <ContactCallToActions
          contact={contact} />) : null}
      </Box>
      <Box marginTop={2}>
        <Button
          disableElevation
          component="label"
          variant="contained"
          sx={{ textTransform: "none" }}
          fullWidth
          //onClick={}
        >
          Encontré esta mascota
        </Button>
      </Box>
    </Box>
  );
}

export default PetRecord;
