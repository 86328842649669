import {
  Box,
  Divider,
  Grid,
  IconButton
} from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import InstagramIcon from "@mui/icons-material/Instagram";
import * as React from "react";
import { ContactModel } from "../../models/contact.model";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function ContactCallToActions({ contact }: {
  contact: ContactModel
}) {
  return (
    <Box>
      <Box marginTop={2} marginBottom={1}>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={6} textAlign="left">
            {contact.nickname}
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="delete" size="large" href={"tel:" + contact.phone} target="_blank">
              <CallIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="delete" size="large"
                        href={"https://wa.me/" + contact.phone.replace(/\D/g, "")} target="_blank">
              <WhatsAppIcon />
            </IconButton>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <IconButton aria-label="delete" size="large" href={"https://www.instagram.com/" + contact.instagram_account}
                        target="_blank">
              <InstagramIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Divider variant="fullWidth" />
    </Box>
  );
}

export default ContactCallToActions;